'use client'

import SectionLayout from '@component/layout/section'
import SectionSwiper from '@component/layout/section/swiper'
import Select from '@component/select'
import useFetch from '@hooks/useFetch'
import { find, findIndex, get, isEqual, map, pick, range, replace } from 'lodash-es'
import { useMemo, useState } from 'react'
import SuburbCard, { SkeletonCard } from '../card/suburb'
import { hubpageDataLayer } from '@plugin/google/gtm/modules/hubpage'

export default function Content({
  regionId,
  fristData,
  firstParams,
  hubdata
}) {

  const [suburbIdx, setSuburbIdx] = useState(0)
  const [price, setPrice] = useState(null)
  const [suburb, setSuburb] = useState(null)

  // find properties url and params
  const [fetchCountUrl, fetchCountParams] = useMemo(() => {
    let url = null
    let params = { typeId: 1 }

    if (price && suburb) {
      params.regionId = suburb.value
      params.price = price.value
      url = 'enum/locations-count'
    }

    return [
      url,
      params
    ]
  }, [price, suburb])
  // 是否显示的首页数据
  const showFirstData = isEqual(firstParams, fetchCountParams)
  const { data, isLoading } = useFetch(showFirstData ? null : { url: fetchCountUrl, params: fetchCountParams })
  // 渲染数据
  const viewData = showFirstData ? fristData : data

  // 显示的region数据
  const regionData = useMemo(() => {
    let res = []
    if (regionId) {
      let defaultIdx = 0
      res = map(regionId, (item, idx) => {
        if (item.defaultSelect) defaultIdx = idx
        return pick(item, ['label', 'value'])
      })
      setSuburbIdx(defaultIdx)
      setSuburb(res[defaultIdx])
    }
    return res
  }, [regionId])

  // 选择region对应的price数据
  const priceData = useMemo(() => {
    let res = []
    if (regionId) {
      const priceArray = regionId[suburbIdx].price
      res = map(priceArray, item => pick(item, ['label', 'value']))
      if (price) {
        let idx = findIndex(res, price)
        if (idx === -1) setDefaultPrice(priceArray)
      } else {
        // setPrice(res[0])
        setDefaultPrice(priceArray)
      }
    }

    function setDefaultPrice(data) {
      const price = find(data, item => item.defaultSelect) || data[0]
      setPrice(pick(price, ['label', 'value']))
    }

    return res
  }, [suburbIdx, regionId, price])

  const title = useMemo(() => {
    return (
      <div className='flex items-center flex-wrap gap-x-6'>
        Find properties under <Select className='text-xl' labelClassName='md:text-3xl' value={price} data={priceData} onChange={priceChangeHandle}></Select> in <Select className='text-xl' labelClassName='md:text-3xl' value={suburb} data={regionData} onChange={suburbChangeHandle}></Select>
      </div>
    )
  }, [priceData, regionData, price, suburb])

  function priceChangeHandle(item) {
    setPrice(item)
    hubpageDataLayer.findProperty.priceChange(replace(item.label, '$', ''))
  }
  function suburbChangeHandle(item, idx) {
    setSuburb(item)
    setSuburbIdx(idx)
    hubpageDataLayer.findProperty.regionChange(item.label)
  }

  function itemClick() {
    const pageType = get(hubdata, 'data.dataLayer.pageType')
    const verticalType = get(hubdata, 'data.dataLayer.verticalType')
    hubpageDataLayer.findProperty.itemClick(pageType, verticalType)
  }

  return regionId && (
    <SectionLayout title={title} className='section-wrap'>
      {
        isLoading && (
          <SectionSwiper data={range(0, 4)} Card={SkeletonCard}></SectionSwiper>
        )
      }
      {
        viewData && !isLoading && (
          <SectionSwiper
            data={viewData}
            dataKey='id'
            slideClassName='py-12'
            swiperClassName='-my-12'
            Card={SuburbCard}
            cardParams={{ price, onClick: itemClick }}
          ></SectionSwiper>
        )
      }
    </SectionLayout>
  )
}
