import classNames from 'classnames'

export function SkeletonContentBox({
  showTitle = true,
  className = 'pt-14',
}) {
  return (
    <div className={classNames('skeleton-wrap', className)}>
      {
        showTitle && (
          <span className='h-24 w-1/4 mb-16'></span>
        )
      }
      <div className='grid gap-16 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4'>
        <span className='h-270'></span>
        <span className='h-270'></span>
        <span className='h-270'></span>
        <span className='h-270'></span>
      </div>
    </div>
  )
}

export function SkeletonContentBoxLine1({
  showTitle = true,
  className = 'pt-14',
  height = 285,
}) {
  return (
    <div className={classNames('skeleton-wrap', className)}>
      {
        showTitle && (
          <span className='h-24 w-1/4 mb-16'></span>
        )
      }
      <div className='grid gap-16 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4'>
        <span style={{ height: height }}></span>
        <span style={{ height: height }} className='!hidden sm:!block'></span>
        <span style={{ height: height }} className='!hidden md:!block'></span>
        <span style={{ height: height }} className='!hidden lg:!block'></span>
      </div>
    </div>
  )
}

export function SkeletonContentBoxSingle({
  height = 285
}) {
  return (
    <div className='skeleton-wrap'>
      <span style={{ height: height }}></span>
    </div>
  )
}