'use client'

import Pagination from '@component/pagination'
import useProgressRouter from '@hooks/useProgressRouter'
import { getRouterHref, URLS } from '@router'
import { getSlug } from '@utils'

export default function PaginationContent({
  hubdata,
  id,
  pageSize,
  count
}) {

  const { pushRouter } = useProgressRouter()
  function changeHandle(idx) {
    pushRouter(getRouterHref(URLS.newsCarouselList, { slug: getSlug(hubdata.name, id) }, { page: idx, size: pageSize, type: hubdata.hubType }))
  }

  return (
    <section className='section-wrap'>
      <Pagination right pageIndex={1} pageSize={pageSize} totals={count} onChange={changeHandle}></Pagination>
    </section>
  )
}