import Cover from '@component/cover'
import { newsDetail } from '@router'
import { joinStr } from '@utils'
import DisablePrefetchLink from '@component/disablePrefetchLink'

export default function NewsCard({
  data,
  hubdata
}) {

  return (
    <DisablePrefetchLink href={newsDetail(data, hubdata.newsUsePrefix ? joinStr([hubdata.hubType, hubdata.slug], '/') : undefined)} className='bg-white p-16 flex space-x-10 rounded-sm'>
      <Cover src={data.coverImg} alt={data.subject} hoverScale className='rounded-sm flex-shrink-0 w-110' width={110} height={94} defaultIcon='icon-news-cover' iconSize='!text-2xl'></Cover>
      <div className='flex-1 min-w-0 flex flex-col justify-between'>
        <div className='text-sm text-neutral-5'>{data.author}</div>
        <div className='text-base text-font line-clamp-2'>{data.subject}</div>
      </div>
    </DisablePrefetchLink>
  )
}

export function SkeletonCard() {
  return (
    <div className='skeleton-wrap'>
      <span className='h-126'></span>
    </div>
  )
}