import Cover from '@component/cover'
import { builderDetail } from '@router'
import { first } from 'lodash-es'
import DisablePrefetchLink from '@component/disablePrefetchLink'

export default function BuilderCard ({
  data
}) {

  const cover = first(data.ossImages)
  return (
    <div className='px-4 pb-8'>
      <DisablePrefetchLink href={builderDetail(data)} className='rounded-sm overflow-hidden shadow-md group block'>
        <Cover src={cover} alt={data.label} hoverScale width={268} height={140}></Cover>
        <div className='px-12 py-14'>
          <div className='text-font text-sm line-clamp-1'>{data.name}</div>
          <div className='text-neutral-5 text-xs line-clamp-2 mt-8 min-h-[30px]'>{data.address}</div>
        </div>
      </DisablePrefetchLink>
    </div>
  )
}

export function SkeletonCard () {
  return (
    <div className='skeleton-wrap h-185'>
      <span className='h-125'></span>
      <div className='flex justify-between mt-10'>
        <span className='w-100 h-10'></span>
        <span className='w-60 h-10'></span>
      </div>
    </div>
  )
}