'use client'

import useLogin from '@hooks/useLogin'
import { RecentViewManager } from '@plugin/recentView'
import { size } from 'lodash-es'
import { createContext, useCallback, useContext, useMemo, useState } from 'react'

export const tabValue = {
  RECENTLY_VIEWED: 'recentlyViewed',
  SAVED_SEARCH: 'savedSearch',
  RECOMMENDED: 'recommended',
  SAVED_LISTING: 'savedListing'
}

export const FastContext = createContext()

export function FastProvider({
  children
}) {
  const { loaded, isLogin } = useLogin()
  const tabs = useMemo(() => {
    const data = RecentViewManager.getCacheList()
    const res = [
      {
        text: 'Saved Search',
        label: 'saved searches',
        value: tabValue.SAVED_SEARCH
      },
      {
        text: 'Saved Listing',
        label: 'saved listings',
        value: tabValue.SAVED_LISTING
      },
    ]
    if (size(data) > 0) {
      res.unshift({
        text: 'Recent searches',
        label: 'recent searches',
        value: tabValue.RECENTLY_VIEWED
      })
      res.push({
        text: 'Recommended',
        label: 'recommended',
        value: tabValue.RECOMMENDED
      })
    } else {
      res.unshift({
        text: 'Recommended',
        label: 'recommended',
        value: tabValue.RECOMMENDED
      })
    }

    return res
  }, [])

  const [tab, setTab] = useState(tabs[0])
  const handleChangeTab = useCallback((tab) => () => setTab(tab), [setTab])

  const state = useMemo(() => ({
    tab,
    tabs,
    handleChangeTab,
    setTab,
    isLogin,
    loaded
  }), [tab, tabs, handleChangeTab, setTab, isLogin, loaded])

  return (
    <FastContext.Provider value={state}>
      {children}
    </FastContext.Provider>
  )
}

export function useFastContext() {
  return useContext(FastContext)
}