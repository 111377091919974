import { assign, concat } from 'lodash-es'
import SearchRoute from '../index'
import { suburbsToIds } from '../utils'

class BuilderRoute extends SearchRoute {
  constructor() {
    super()
  }

  reset() {
    this.region = []
    this.district = []
    this.suburb = []
    this.page = 1
    return this
  }

  toQuery(pageSize) {

    const {
      region,
      district,
      suburb,
      page
    } = this

    const subrubIds = suburbsToIds(concat(region, district, suburb)).idStr()
    return assign(
      {
        ...subrubIds,
      },
      pageSize ? { offset: (page - 1) * pageSize, limit: pageSize } : undefined,
    )
  }
}

export default function getBuilderRoute() {
  return new BuilderRoute()
}