'use client'

import { SkeletonContentBoxLine1 } from '@component/skeleton/contentBox'
import IconFavourite from '@icon/house/favourite.svg'
import dynamic from 'next/dynamic'
import { useUnmount } from 'react-use'
import { useSWRConfig } from 'swr'
import { tabValue, useFastContext } from '../context'
import RecentlyViewed from './recentlyViewed'

const LoadingComponent = () => <SkeletonContentBoxLine1 showTitle={false} className='' />
const Recommended = dynamic(() => import('./recommended'), { ssr: false, loading: LoadingComponent })
const SavedListing = dynamic(() => import('./savedListing'), { ssr: false, loading: LoadingComponent })
const SavedSearch = dynamic(() => import('./savedSearch'), { ssr: false, loading: LoadingComponent })

export default function FastContent() {
  const { tab } = useFastContext()

  const { cache } = useSWRConfig()
  useUnmount(() => {
    cache.clear()
  })

  return (
    <section className='mt-16 sm:mt-30'>
      {
        tab.value === tabValue.RECENTLY_VIEWED && <RecentlyViewed />
      }
      {
        tab.value === tabValue.SAVED_SEARCH && <SavedSearch />
      }
      {
        tab.value === tabValue.SAVED_LISTING && <SavedListing />
      }
      {
        tab.value === tabValue.RECOMMENDED && <Recommended />
      }
    </section>
  )
}

export function NotLogin({
  title = '',
  sub,
  icon
}) {

  return (
    <section className='sm:pr-50'>
      <div
        className='rounded-2xl w-[660px] max-w-full py-30 px-30 gap-x-10 flex flex-col-reverse items-center sm:px-26 sm:py-40 sm:flex-row'
        style={{ background: 'linear-gradient(180deg, rgba(231, 231, 231, 0.30) 6.36%, rgba(243, 245, 247, 0.00) 85.45%)' }}
      >
        <div className='flex-1 min-w-0 text-center sm:text-left'>
          <div className='text-lg text-font'>{title}</div>
          <div className='text-sm sm:text-base text-font sm:text-neutral-5 mt-20'>{sub}</div>
        </div>
        <div className='sm:-mr-60'>
          {icon}
        </div>
      </div>
    </section>
  )
}

export function Empty({
  title = '',
  sub,
  icon
}) {

  return (
    <section className='sm:pr-50'>
      <div
        className='rounded-2xl w-[560px] max-w-full py-40 px-20 flex gap-x-10 sm:px-26 relative'
        style={{ background: 'linear-gradient(180deg, rgba(231, 231, 231, 0.30) 6.36%, rgba(243, 245, 247, 0.00) 85.45%)' }}
      >
        <div className='flex-1 min-w-0 mr-80 sm:mr-0'>
          <div className='text-lg sm:text-xl text-font'>{title}</div>
          <div className='text-sm sm:text-base text-font sm:text-neutral-5 mt-20'>{sub}</div>
        </div>
        <div className='absolute right-12 bottom-10 sm:static sm:-mr-60'>
          {icon}
        </div>
      </div>
    </section>
  )
}

export function FavouriteIcon() {
  return (
    <i className='w-30 h-30 rounded-half inline-flex items-center justify-center bg-white shadow-md mx-10 cursor-pointer hover:bg-primary-light'>
      <IconFavourite className='stroke-primary w-16 h-16 pointer-events-none fill-none' />
    </i>
  )
}