'use client'

import DisablePrefetchLink from '@component/disablePrefetchLink'
import { map } from 'lodash-es'
import { usePathname } from 'next/navigation'
import { useEffect, useMemo } from 'react'
import { useHash } from 'react-use'
import classNames from 'classnames'
import { buildUrl } from '@plugin/http/lib/utils'

export default function Tabs ({
  data,
  hubdata
}) {

  const [hash, setHash] = useHash()
  const path = usePathname()
  const viewData = useMemo(() => {
    return map(data, item => {
      if (!item.link) {
        item.sluglink = buildUrl(path, undefined, undefined, item.title)
      }
      return item
    })
  }, [data, path])

  function itemClick (item) {
    return () => {
      if (item) {
        if (item.sluglink) {
          setHash(item.title)
          scrollTo(item.title)
        }
      } else {
        setHash('')
      }
    }
  }

  useEffect(() => {
    setTimeout(() => scrollTo(hash), 120)
  }, [hash])

  function scrollTo (id) {
    if (!id) return
    const body = document.getElementById(id.replace(/#/, ''))
    body && body.scrollIntoView({ behavior: 'smooth' })
  }
  
  return (
    <div className='border-b'>
      <div className='section-wrap'>
        <div className='space-x-16 sm:space-x-40 whitespace-nowrap overflow-auto'>
          <TabItem href={path} active={!hash} onClick={itemClick()}>{hubdata.name}</TabItem>
          {
            map(viewData, item => (
              <TabItem
              href={item.link || item.sluglink}
              target={item.link ? '_blank' : undefined}
              key={item.id}
              active={hash === `#${item.title}`}
              onClick={itemClick(item)}>{item.navTitle || item.title}</TabItem>
            ))
          }
        </div>
      </div>
    </div>
  )
}

function TabItem ({
  children,
  href,
  target,
  active,
  onClick
}) {
  return (
    <DisablePrefetchLink href={href} target={target} className={classNames('h-30 inline-flex items-center text-font text-sm cursor-pointer border-b-2 border-b-transparent sm:h-70 hover:text-primary hover:border-b-primary', {'text-primary !border-b-primary': active})} onClick={onClick} data-noprogress>{children}</DisablePrefetchLink>
  )
}