'use client'

import SectionLayout from '@component/layout/section'
import SectionSwiper from '@component/layout/section/swiper'
import Select from '@component/select'
import { COMMERCIAL_TYPE, HOUSE_TYPE_ID } from '@config'
import useFetch from '@hooks/useFetch'
import { filter, isEqual, min, range } from 'lodash-es'
import { useCallback, useMemo, useState } from 'react'
import SuburbCard, { SkeletonCard } from '../card/suburb'
import { hubpageDataLayer } from '@plugin/google/gtm/modules/hubpage'

export default function Content({
  item,
  firstData,
  isCommercial,
  isBusiness,
  isNewHomes,
  isRural,
  isApartmentsTerraces,
  isHouseLand,
  isHomeBuilder,
  defaultTypeId,
  fetchUrl: defaultFetchUrl,
  fetchParams: defaultFetchParams
}) {

  const [typeId, setTypeId] = useState(defaultTypeId)
  function typeIdChangeHandle(type) {
    setTypeId(type.value)
  }

  const title = useMemo(() => {
    let res = null
    const { title, titleType } = item
    switch (parseInt(titleType)) {
      case 1:
        res = title
        break
      case 2:
        if (parseInt(title) === 1) {
          res = (
            <div className='flex items-center flex-wrap'>Explore all <Select value={typeId} data={[
              { label: 'commercial for sale', value: HOUSE_TYPE_ID[COMMERCIAL_TYPE.sale] },
              { label: 'commercial for lease', value: HOUSE_TYPE_ID[COMMERCIAL_TYPE.lease] }
            ]} onChange={typeIdChangeHandle} className='text-xl mx-6' labelClassName='md:text-3xl' popWidth={300}></Select> by region</div>
          )
        }
        break
      default:
        res = title
        break
    }
    return res
  }, [item, typeId])

  const [fetchUrl, fetchParams] = useMemo(() => {
    let url
    let params
    if (isCommercial || isBusiness || isRural) {
      url = 'enum/locations-count'
    } else if (isHomeBuilder) {
      url = 'builder/homepage/regions'
    } else if (isNewHomes) {
      url = 'enum/homepage/regions'
    }
    if (isCommercial || isBusiness || isRural) {
      params = { typeId }
    } else if (isApartmentsTerraces) {
      params = { isProject: 1, typeId: 1, isLandHome: 0 }
    } else if (isHouseLand) {
      params = { isProject: 1, typeId: 1, isLandHome: 1 }
    } else if (isNewHomes) {
      params = { isProject: 1, typeId: 1 }
    }
    return [url, params]
  }, [isCommercial, isBusiness, isRural, isNewHomes, isApartmentsTerraces, isHouseLand, isHomeBuilder, typeId])

  const viewFirstData = fetchUrl === defaultFetchUrl && isEqual(fetchParams, defaultFetchParams)
  const { data, isLoading } = useFetch(viewFirstData ? null : { url: fetchUrl, params: fetchParams })
  const viewData = useMemo(() => {
    return filter(viewFirstData ? firstData : data, item => (item.houseCount || item.builderFranchiseCount))
  }, [data, viewFirstData, firstData])

  const clickHandle = useCallback(() => {
    hubpageDataLayer.exploreByRegion.itemClick()
  }, [])

  return (
    <SectionLayout title={title} className='section-wrap'>
      {
        isLoading && (
          <SectionSwiper data={range(0, 4)} Card={SkeletonCard}></SectionSwiper>
        )
      }
      {
        viewData && !isLoading && (
          <SectionSwiper
            slidesPerView={min([viewData.length, 5])}
            data={viewData}
            dataKey='id'
            Card={SuburbCard}
            cardParams={{ typeId, isNewHomes, isApartmentsTerraces, isHouseLand, isHomeBuilder }}
            slideClick={clickHandle}
            slideClassName='py-12'
            swiperClassName='-my-12'
          ></SectionSwiper>
        )
      }
    </SectionLayout>
  )
}
