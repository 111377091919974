'use client'

import { SkeletonContentBoxSingle } from '@component/skeleton/contentBox'
import { RecentData } from '@plugin/recentView'
import dynamic from 'next/dynamic'

const Loading = () => <SkeletonContentBoxSingle height={250} />
const EstimateByListingCard = dynamic(() => import('./estimateByListing'), { ssr: false, loading: Loading })
const BuyByListingCard = dynamic(() => import('./buyByListing'), { ssr: false, loading: Loading })
const RentByListingCard = dynamic(() => import('./rentByListing'), { ssr: false, loading: Loading })
const BuyBySuburbCard = dynamic(() => import('./buyBySuburb'), { ssr: false, loading: Loading })
const NewHomesBySuburbCard = dynamic(() => import('./newHomesBySuburb'), { ssr: false, loading: Loading })
const EstimateByAreaCard = dynamic(() => import('./estimateByArea'), { ssr: false, loading: Loading })
const RentBySuburbCard = dynamic(() => import('./rentBySuburb'), { ssr: false, loading: Loading })
const SoldBySuburbCard = dynamic(() => import('./soldBySuburb'), { ssr: false, loading: Loading })
const RecommendRegionCard = dynamic(() => import('./recommend'), { ssr: false, loading: Loading })
const CommercialBySuburbCard = dynamic(() => import('./commercialBySuburb'), { ssr: false, loading: Loading })
const RuralBySuburbCard = dynamic(() => import('./ruralBySuburb'), { ssr: false, loading: Loading })
const BusinessesBySuburbCard = dynamic(() => import('./businessesBySuburb'), { ssr: false, loading: Loading })

export default function RecentViewCard({
  data
}) {
  const recentData = RecentData.createFromJSON(data)

  if (recentData.isEstimateByListing()) {
    return (
      <EstimateByListingCard recentData={recentData} />
    )
  }

  if (recentData.isEstimateByArea()) {
    return (
      <EstimateByAreaCard recentData={recentData} />
    )
  }

  if (recentData.isBuyByListing()) {
    return (
      <BuyByListingCard recentData={recentData} />
    )
  }

  if (recentData.isRentByListing()) {
    return (
      <RentByListingCard recentData={recentData} />
    )
  }

  if (recentData.isBuyBySuburb()) {
    return (
      <BuyBySuburbCard recentData={recentData} />
    )
  }

  if (recentData.isRentBySuburb()) {
    return (
      <RentBySuburbCard recentData={recentData} />
    )
  }

  if (recentData.isSoldBySuburb()) {
    return (
      <SoldBySuburbCard recentData={recentData} />
    )
  }

  if (recentData.isRecommend()) {
    return (
      <RecommendRegionCard recentData={recentData} />
    )
  }

  if (recentData.isNewHomesBySuburb()) {
    return (
      <NewHomesBySuburbCard recentData={recentData} />
    )
  }

  if (recentData.isCommercialForSale() || recentData.isCommercialForLease()) {
    return (
      <CommercialBySuburbCard recentData={recentData} />
    )
  }

  if (recentData.isRuralBySuburb()) {
    return (
      <RuralBySuburbCard recentData={recentData} />
    )
  }

  if (recentData.isBusinessesBySuburb()) {
    return (
      <BusinessesBySuburbCard recentData={recentData} />
    )
  }
}