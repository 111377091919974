'use client'

import DisablePrefetchLink from '@component/disablePrefetchLink'
import SectionLayout from '@component/layout/section'
import useFetch from '@hooks/useFetch'
import classNames from 'classnames'
import { map, range } from 'lodash-es'
import { useState } from 'react'
import NewsCard, { SkeletonCard } from './card'

export default function HubNewsBlockContent ({
  item,
  firstData,
  hubdata
}) {

  const [idx, setIdx] = useState(0)
  const current = item.tabs[idx]
  const { data, isLoading } = useFetch(idx === 0 ? null : {url: 'news/hub-carousel', params: {id: current.carousel, limit: 3}})
  const viewData = idx === 0 ? firstData : data

  return (
    <div className='bg-neutral-1 py-32 md:py-56'>
      <SectionLayout title={item.title} titleLink={item.titleLink} titleRight={<NewsTabs data={item.tabs} idx={idx} onChange={setIdx}></NewsTabs>} className='section-wrap'>
        <div className='grid grid-cols-1 gap-y-10 gap-x-32 md:grid-cols-3'>
            {
              isLoading && (
                map(range(0, 3), i => (<SkeletonCard key={i}></SkeletonCard>))
              )
            }
          {
            viewData && !isLoading && (
              map(viewData, (item, i) => (
                <NewsCard data={item} key={`${item.id}-${i}`} hubdata={hubdata}></NewsCard>
              ))
            )
          }
        </div>
        {
          viewData && current.link && current.link.text && (
            <div className='mt-18 text-right'>
              <DisablePrefetchLink href={current.link.url} className='text-sm text-primary' target='_blank'>{current.link.text}<i className='icon icon-arrow-right !text-xs'></i></DisablePrefetchLink>
            </div>
          )
        }
      </SectionLayout>
    </div>
  )
}

function NewsTabs ({
  data,
  idx,
  onChange
}) {

  function clickHandle (i) {
    return () => {
      onChange(i)
    }
  }

  return (
    <div className='inline-flex bg-white rounded-sm mt-4 overflow-auto w-full md:mt-0 md:w-auto'>{
      map(data, (item, i) => (
        <span key={i} className={classNames('h-48 leading-48 border-b-2 border-b-transparent cursor-pointer flex-1 text-center whitespace-nowrap px-10 md:px-32', {'!border-b-primary text-primary': i === idx })} onClick={clickHandle(i)}>{item.name}</span>
      ))
    }</div>
  )
}
