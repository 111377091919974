'use client'

import DisablePrefetchLink from '@component/disablePrefetchLink'

export default function HubAd ({
  item
}) {

  const { bgImg, mBgImg, bgTabletVertical, bgTabletHorizontal, gradient, width, height } = item

  return (
    <section className='section-wrap'>
      <style jsx>{`
        .cover-bg {
          background:${gradient ? ' linear-gradient(90deg, rgba(0, 0, 0, 0.88) 0%, rgba(0, 0, 0, 0) 84.85%),' : ''} url(${bgImg});
        }
        @media (min-width: 768px) and (max-width: 1280px) and (orientation: landscape) {
          .cover-bg {
            background:${gradient ? ' linear-gradient(90deg, rgba(0, 0, 0, 0.88) 0%, rgba(0, 0, 0, 0) 84.85%),' : ''} url(${bgTabletHorizontal || bgImg});
          }
        }
        @media (min-width: 768px) and (max-width: 1280px) and (orientation: portrait) {
          .cover-bg {
            background:${gradient ? ' linear-gradient(90deg, rgba(0, 0, 0, 0.88) 0%, rgba(0, 0, 0, 0) 84.85%),' : ''} url(${bgTabletVertical || bgImg});
          }
        }
        @media (max-width: 640px) {
          .cover-bg {
            background:${gradient ? ' linear-gradient(90deg, rgba(0, 0, 0, 0.88) 0%, rgba(0, 0, 0, 0) 84.85%),' : ''} url(${mBgImg || bgImg});
          }
        }
      `}</style>
      <div className='cover-bg !bg-no-repeat !bg-cover !bg-center rounded-sm mx-auto max-w-full flex flex-col items-start justify-between px-24 py-20 md:px-64 md:py-40' style={{width: parseInt(width), minHeight: parseInt(height), }}>
        <div className='text-white font-bold text-3xl max-w-588'>{item.text}</div>
        {
          item.buttonText && <DisablePrefetchLink href={item.buttonHref ? item.buttonHref : ''} target='_blank' className='button-orange min-w-[180px] mt-12'>{item.buttonText}</DisablePrefetchLink>
        }
      </div>
    </section>
  )
}