'use client'

import SectionSwiper from '@component/layout/section/swiper'
import CircleCard from './card'

export default function CircleNav({
  item
}) {

  const data = item.list
  return (
    <section className='section-wrap'>
      <SectionSwiper
        breakpoints={
          {
            0: {
              slidesPerView: 2,
              slidesPerGroup: 2,
              spaceBetween: 16
            },
            640: {
              slidesPerView: 3,
              slidesPerGroup: 3,
              spaceBetween: 16
            },
            750: {
              slidesPerView: 4,
              slidesPerGroup: 4,
              spaceBetween: 16
            },
            900: {
              slidesPerView: 6,
              slidesPerGroup: 6,
            },
            1200: {
              slidesPerView: 8,
              slidesPerGroup: 8,
              spaceBetween: 16
            }
          }
        }
        data={data}
        Card={CircleCard}
        slideClassName='py-12'
        swiperClassName='-my-12'
      ></SectionSwiper>
    </section>
  )
}