'use client'

import { useCallback } from 'react'

export default function GoTop({
  id
}) {

  const handleClick = useCallback(() => {
    const el = document.getElementById(id)
    if (el) {
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    } else {
      window.scrollTo({
        behavior: 'smooth',
        top: 0
      })
    }
  }, [id])
  return (
    <div className='fixed inset-x-0 bottom-[15vh] section-wrap z-10'>
      <button
        type='button'
        className='absolute right-[var(--container-pd)] bg-white w-40 h-40 rounded-sm border text-xs flex flex-col items-center justify-center xl:-right-[var(--container-pd)]'
        onClick={handleClick}
      >
        <i className='icon icon-arrow-up'></i>
        TOP
      </button>
    </div>
  )
}