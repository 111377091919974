'use client'

import ListingsStandard from '@component/card/listings/standard'
import NewsStandard from '@component/card/news/standard'
import SectionSwiper from '@component/layout/section/swiper'
import { hubpageDataLayer } from '@plugin/google/gtm/modules/hubpage'
import { get, map, slice } from 'lodash-es'
import { useCallback } from 'react'

export default function Content({
  data,
  item,
  hubdata,
  listName,
  isNews
}) {

  const changeHandle = useCallback(swiper => {
    const { realIndex } = swiper
    const arr = slice(data, realIndex - 1, realIndex + 3)
    const items = map(arr, (d, idx) => getDataReport(d, idx, item.title, listName, hubdata))

    if (!listName) return
    hubpageDataLayer.contentBox.itemClick(items)
  }, [data, item, listName, hubdata])

  const clickHandle = useCallback((data, idx) => {

    if (!listName) return
    hubpageDataLayer.contentBox.itemClick([getDataReport(data, idx, item.title, listName, hubdata)])
  }, [item, listName, hubdata])


  return (
    <SectionSwiper
      data={data}
      dataKey='id'
      slideClassName={isNews ? undefined : 'py-12'}
      swiperClassName={isNews ? undefined : '-my-12'}
      Card={isNews ? NewsStandard : ListingsStandard}
      slideClick={clickHandle}
      cardParams={isNews ? { hubdata } : undefined}
      onChange={changeHandle}
    ></SectionSwiper>
  )
}

function getDataReport(data, idx, title, listName, hubdata) {

  const nzmeInfo = get(data, 'nzmeInfo')
  const vertical = get(hubdata, 'data.dataLayer.vertical')
  const verticalType = get(hubdata, 'data.dataLayer.verticalType')
  const pageType = get(hubdata, 'data.dataLayer.pageType')

  return {
    item_id: data.id,
    item_name: [data.street, data.suburb_name, data.district, data.region].join(', '),
    index: idx,
    item_category: nzmeInfo && nzmeInfo.type || 'dev_fallback',
    item_category2: [vertical, verticalType, pageType].join('_'),
    item_list_id: title,
    item_list_name: listName,
    item_variant: nzmeInfo ? `${nzmeInfo.mv}|${nzmeInfo.rank}` : '',
    location_id: vertical
  }
}