'use client'

import RecentViewCard from '@component/card/recentView'
import SectionSwiper from '@component/layout/section/swiper'
import { SkeletonContentBoxLine1 } from '@component/skeleton/contentBox'
import useFetch from '@hooks/useFetch'
import { RecentType, RecentViewManager } from '@plugin/recentView'
import { suburbsToIds } from '@plugin/searchRoute/utils'
import { flatten, get, map, size } from 'lodash-es'
import { useEffect, useMemo } from 'react'
import { useBoolean, useList, useMount } from 'react-use'

export default function RecentlyViewed() {

  const [list, { set: setList, push: pushList }] = useList([])
  const [loading, toggleLoading] = useBoolean(true)
  const [showRecommend, toggleShowRecommend] = useBoolean(false)

  const [recommendParams, recentTypes] = useMemo(() => {
    const suburbs = flatten(map(list, item => get(item, 'data.suburbs') || []))
    return [
      suburbsToIds(suburbs).idStr(),
      map(list, 'type')
    ]
  }, [list])

  const { data } = useFetch(showRecommend ? { url: 'location-detail', params: recommendParams } : { url: null })
  const [isAppendRecommend, setIsAppendRecommend] = useBoolean(false)
  useEffect(() => {
    if (!isAppendRecommend && data) {
      pushList({ type: RecentType.recommend, data: { ...data, recentTypes } })
      toggleLoading(false)
      setIsAppendRecommend(true)
    }
  }, [data, recentTypes, isAppendRecommend, pushList, toggleLoading, setIsAppendRecommend])

  useMount(() => {
    const data = RecentViewManager.getCacheList()
    const showRecommend = size(data) < 4
    setList(data)
    toggleShowRecommend(showRecommend)
    if (!showRecommend) toggleLoading(false)
  })

  if (loading) {
    return (
      <SkeletonContentBoxLine1 showTitle={false} className='' />
    )
  }

  return (
    <SectionSwiper
      data={list}
      dataKey='id'
      slideClassName='pb-8'
      swiperClassName='-mb-8'
      Card={RecentViewCard}
    />
  )
}