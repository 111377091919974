import { PageViewLayer } from '@component/dataLayer'
import { pushDataLayer } from '../index'

export default function PageViewDataLayer() {

  return (
    <PageViewLayer
      data={{
        'vertical': 'form',
        'vertical_type': 'form',
        'event': 'page_view',
        'page_type': 'detail'
      }}
    />
  )
}

export const formDataLayer = {
  submitClick() {
    pushDataLayer({
      'vertical': 'form',
      'vertical_type': 'form',
      'page_type': 'detail'
    })
  }
}