'use client'

import GoogleAdvert from '@component/google/advert'
import { global } from '@store/index'
import md5 from 'blueimp-md5'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'

export default function HubGoogleAd({
  item,
  id,
  className
}) {

  const [mobile, setIsMobile] = useState(false)
  const [show, setShow] = useState(true)
  const { isMobile } = useRecoilValue(global)
  const { adsize, adunit, madsize, madunit, width, mwidth, dfpPath, pt, oid, kw } = item
  const elId = id || md5(JSON.stringify(item))
  const vw = mobile ? mwidth : width
  const styles = vw ? { width: parseInt(vw) } : {}

  useEffect(() => setIsMobile(isMobile), [setIsMobile, isMobile])

  function onloadHandle(v) {
    setShow(v)
  }

  return (
    <section className={classNames('section-wrap', { 'hidden': !show }, className)}>
      {
        mobile
          ? <GoogleAdvert id={elId} adsize={madsize} adunit={madunit} dfpPath={dfpPath} dfpTags={{ pt, oid, kw }} style={styles} onLoad={onloadHandle}></GoogleAdvert>
          : <GoogleAdvert id={elId} adsize={adsize} adunit={adunit} dfpPath={dfpPath} dfpTags={{ pt, oid, kw }} style={styles} onLoad={onloadHandle}></GoogleAdvert>
      }
    </section>
  )
}