'use client'

import SectionLayout from '@component/layout/section'
import SectionSwiper, { SWIPER_BREAKPOINTS } from '@component/layout/section/swiper'
import Select from '@component/select'
import { COMMERCIAL_TYPE, HOUSE_TYPE_ID } from '@config'
import useFetch from '@hooks/useFetch'
import { filter, isEqual, min, range, size } from 'lodash-es'
import { useMemo, useState } from 'react'
import TypeCard, { SkeletonCard } from '../card/type'

export default function Content({
  item,
  firstData,
  isCommercial,
  isNewHomes,
  isRural,
  defaultTypeId,
  fetchUrl: defaultFetchUrl,
  fetchParams: defaultFetchParams
}) {

  const [typeId, setTypeId] = useState(defaultTypeId)
  function typeIdChangeHandle(type) {
    setTypeId(type.value)
  }

  const title = useMemo(() => {
    let res = null
    const { title, titleType } = item
    switch (parseInt(titleType)) {
      case 1:
        res = title
        break
      case 2:
        if (parseInt(title) === 1) {
          res = (
            <div className='flex items-center flex-wrap'>Explore all <Select value={typeId} data={[
              { label: 'commercial for sale', value: HOUSE_TYPE_ID[COMMERCIAL_TYPE.sale] },
              { label: 'commercial for lease', value: HOUSE_TYPE_ID[COMMERCIAL_TYPE.lease] }
            ]} onChange={typeIdChangeHandle} className='text-xl mx-6' labelClassName='md:text-3xl' popWidth={300}></Select> by type</div>
          )
        }
        break
      default:
        res = title
        break
    }
    return res
  }, [item, typeId])

  const [fetchUrl, fetchParams] = useMemo(() => {
    let url
    let params
    if (isCommercial || isRural) {
      url = `enum/type/${typeId}`
      params = { getHouseCount: 1 }
    } else if (isNewHomes) {
      url = 'enum/type-new-homes'
    }
    return [url, params]
  }, [isCommercial, isRural, isNewHomes, typeId])

  const viewFirstData = fetchUrl === defaultFetchUrl && isEqual(fetchParams, defaultFetchParams)
  const { data, isLoading } = useFetch(viewFirstData ? null : { url: fetchUrl, params: fetchParams })
  const viewData = useMemo(() => {
    return filter(viewFirstData ? firstData : data, item => (item.houseCount || item.builderFranchiseCount))
  }, [data, viewFirstData, firstData])

  return (
    <SectionLayout title={title} className='section-wrap'>
      {
        isLoading && (
          <SectionSwiper slidesPerView={4} data={range(0, 4)} Card={SkeletonCard}></SectionSwiper>
        )
      }
      {
        viewData && !isLoading && (
          <SectionSwiper
            slidesPerView={min([viewData.length, 6])}
            data={viewData}
            dataKey='id'
            cardParams={{ params: { typeId, isNewHomes } }}
            Card={TypeCard}
            showNext={false}
            swiperClassName='!pr-30 sm:!pr-0'
            breakpoints={size(viewData) >= 6 ? SWIPER_BREAKPOINTS.typeCard : undefined}
          ></SectionSwiper>
        )
      }
    </SectionLayout>
  )
}
