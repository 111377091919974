'use client'

import Select from '@component/select'
import classNames from 'classnames'
import { map } from 'lodash-es'
import { useFastContext } from '../context'

export default function Tabs() {
  const { tab, tabs, handleChangeTab } = useFastContext()
  return (
    <div className='gap-x-16 hidden sm:flex'>
      {
        map(tabs, (item) => (
          <button
            className={classNames(
              'h-40 px-14 md:px-24 rounded-[100px] flex items-center justify-center text-base border',
              tab.value === item.value ? 'border-primary text-primary bg-primary-light' : ''
            )}
            onClick={handleChangeTab(item)}
            key={item.value}
          >
            {item.text}
          </button>
        ))
      }
    </div>
  )
}

export function TabsTitle() {
  const { tab, tabs, setTab } = useFastContext()
  return (
    <div className='flex items-center flex-wrap gap-x-6 sm:hidden'>
      Find your <Select className='text-xl' labelClassName='md:text-3xl' value={tab} data={tabs} onChange={setTab}></Select>
    </div>
  )
}