'use client'

import Cover from '@component/cover'
import DisablePrefetchLink from '@component/disablePrefetchLink'
import { hubpageDataLayer } from '@plugin/google/gtm/modules/hubpage'
import { get } from 'lodash-es'

export default function Card({
  data
}) {

  function handleClick() {
    hubpageDataLayer.orGuide.itemClick(get(data, 'eventName'))
  }

  return (
    <DisablePrefetchLink href={data.link} onClick={handleClick} className='group block'>
      <Cover src={data.cover} alt={data.title} hoverScale className='rounded-sm' width={358} height={200}></Cover>
      <div className='text-font text-base line-clamp-1 mt-8'>{data.title}</div>
      <div className='text-neutral-5 text-sm line-clamp-2 mt-8'>{data.sub}</div>
    </DisablePrefetchLink>
  )
}
