'use client'

import SectionLayout from '@component/layout/section'
import SectionSwiper from '@component/layout/section/swiper'
import DevelopmentCard from '../card/builder'

export default function Content ({
  item,
  data
}) {

  return (
    <SectionLayout title={item.title} className='section-wrap'>
      <SectionSwiper data={data} dataKey='id' Card={DevelopmentCard}></SectionSwiper>
    </SectionLayout>
  )
}