'use client'

import Cover from '@component/cover'
import { global } from '@store/index'
import { useRecoilValue } from 'recoil'

export default function NewsAds ({
  data
}) {

  const { isMobile } = useRecoilValue(global)
  const { bgImg, mBgImg, width, height, text = 'ads' } = data

  return (
    <div className='max-w-full min-w-0' style={{width: parseInt(width), height: parseInt(height)}}>
      <Cover src={isMobile && mBgImg ? mBgImg : bgImg} alt={text} width={width} height={height}></Cover>
    </div>
  )
}