import Autocomplete from '@component/autocomplete'
import classNames from 'classnames'

export default function InputContent({
  disabled = false,
  onSelect,
  placeholder,
  error,
  className,
  innerClassName,
  fetcher,
  labelKey,
  onKeywordChange={onKeywordChange}
}) {

  return (
    <Autocomplete
      className={className}
      inputClassName={classNames(innerClassName, { 'border-red': !!error })}
      fetcher={fetcher}
      onSelect={onSelect}
      labelKey={labelKey}
      placeholder={placeholder}
      disabled={disabled}
      onKeywordChange={onKeywordChange}
    />
  )
}